export default [
  {
    title: 'Dashboard',
    route: 'manage-home',
    icon: 'ActivityIcon',
  },

  /* Account */
  {
    header: 'Customer Management ',
  },
  {
    title: 'User Management',
    icon: 'UserIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Users',
        route: 'manage-user',
      },
      {
        title: 'User Detail',
        route: 'manage-user-save',
        hidden: true,
      },

      {
        title: 'User Personels',
        route: 'manage-user-personel',
      },
      {
        title: 'User Personel Detail',
        route: 'manage-user-personel-save',
        hidden: true,
      },
    ],
  },
  {
    title: 'Products',
    icon: 'ArchiveIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Products',
        route: 'manage-product',
      },
      {
        title: 'Promotions',
        route: 'manage-order-promotion',
      },
      {
        title: 'Archived Promotions',
        route: 'manage-order-promotion-archived',
      },
    ],
  },
  {
    title: 'Orders',
    icon: 'ListIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'G25',
        route: 'manage-order',
        children: [
          {
            title: 'Orders',
            route: 'manage-order',
          },
          {
            title: 'Pending',
            route: 'manage-order-pending',
          },
          {
            title: 'Archived',
            route: 'manage-order-archived',
          },
        ],
      },
      {
        title: 'Traits & Health',
        route: 'manage-order-trait',
        children: [
          {
            title: 'Orders',
            route: 'manage-order-trait',
          },
          {
            title: 'Pending',
            route: 'manage-order-trait-pending',
          },
          {
            title: 'Archived',
            route: 'manage-order-trait-archived',
          },
        ],
      },
    ],
  },
  {
    title: 'Payments',
    route: 'manage-payment',
    icon: 'CreditCardIcon',
  },

  {
    title: 'Logs',
    icon: 'AlertOctagonIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Order Logs',
        route: 'manage-order-logs',
      },
      {
        title: 'Login Logs',
        route: 'manage-login-logs',
      },
    ],
  },

  {
    title: 'Customer Tickets',
    icon: 'MessageSquareIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Open Tickets',
        route: 'manage-ticket-open',
      },
      {
        title: 'Closed Tickets',
        route: 'manage-ticket-closed',
      },
      {
        title: '',
        route: 'manage-ticket-detail',
        hidden: true,
      },
    ],
  },

  {
    title: 'Reports',
    icon: 'FileTextIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Order Reports',
        route: 'manage-report-order',
      },
    ],
  },
  {
    title: 'YSEQ Data',
    icon: 'FileTextIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'User Data List',
        route: 'manage-business-partner-link',
      },
    ],
  },

  /* Definations */
  {
    header: 'Data Management',
  },

  {
    title: 'Ethnicities',
    icon: 'CompassIcon',
    tagVariant: 'light-warning',
    route: 'manage-preset-ethnicity',
  },

  {
    title: 'Ancestry Regions',
    icon: 'MapIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Region Groups',
        route: 'manage-preset-region-grup',
      },
      {
        title: 'Regions',
        route: 'manage-preset-region',
      },
      {
        title: 'Sub-Regions-Detail-1',
        route: 'manage-preset-region-grup-save',
        hidden: true,
      },
      {
        title: 'Sub-Regions-Detail-2',
        route: 'manage-preset-region-save',
        hidden: true,
      },
    ],
  },

  {
    title: 'PCA Regions',
    icon: 'MapIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Pca Region Groups',
        route: 'manage-pca-region-grup',
      },
      {
        title: 'Pca Regions',
        route: 'manage-pca-region',
      },
      {
        title: 'Sub-Regions-Detail-3',
        route: 'manage-pca-region-grup-save',
        hidden: true,
      },
      {
        title: 'Sub-Regions-Detail-4',
        route: 'manage-pca-region-save',
        hidden: true,
      },
    ],
  },

  {
    title: 'Ancient Periods',
    route: 'manage-preset-ancient-period',
    icon: 'ColumnsIcon',
  },

  {
    title: 'Mixed Mode Period',
    icon: 'ColumnsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Ancient',
        route: 'manage-preset-ancient-mixedmode-period',
      },
      {
        title: 'ancient-mixedmode-Detail-3',
        route: 'manage-preset-ancient-mixedmode-period-save',
        hidden: true,
      },
      {
        title: 'Modern',
        route: 'manage-preset-modern-mixedmode-period',
      },
      {
        title: 'modern-mixedmode-Detail-3',
        route: 'manage-preset-modern-mixedmode-period-save',
        hidden: true,
      },
    ],
  },

  {
    title: 'Source Data',
    route: 'manage-source-data',
    icon: 'DatabaseIcon',
  },
  {
    title: 'Periodical Proximity',
    route: 'manage-preset-map',
    icon: 'LayoutIcon',
  },
  {
    title: 'Ancient Proximity ',
    route: 'manage-ancient-prox',
    icon: 'LayoutIcon',
  },
  {
    title: 'Ancient (Individual) ',
    route: 'manage-ancient-individual',
    icon: 'LayoutIcon',
  },
  {
    title: 'Farmers&HGs',
    route: 'manage-preset-farmsers-hgs',
    icon: 'LayoutIcon',
  },

  /* Trait Data */
  {
    header: 'Trait',
  },
  {
    title: 'Trait Data',
    icon: 'LayoutIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Trait Category',
        route: 'manage-trait-category',
      },
      {
        title: 'trait category',
        route: 'manage-trait-category-save',
        hidden: true,
      },

      {
        title: 'Trait Genotype',
        route: 'manage-trait-genotype',
      },
      {
        title: 'trait genotype',
        route: 'manage-trait-genotype-save',
        hidden: true,
      },

      {
        title: 'Trait Marker',
        route: 'manage-trait-marker',
      },
      {
        title: 'trait marker',
        route: 'manage-trait-marker-save',
        hidden: true,
      },

      {
        title: 'Trait Feature',
        route: 'manage-trait-feature',
      },
      {
        title: 'trait feature',
        route: 'manage-trait-feature-save',
        hidden: true,
      },

      {
        title: 'Trait Effect',
        route: 'manage-trait-effect',
      },
      {
        title: 'trait effect',
        route: 'manage-trait-effect-save',
        hidden: true,
      },

      {
        title: 'Trait Population',
        route: 'manage-trait-population',
      },
      {
        title: 'trait Population',
        route: 'manage-trait-population-save',
        hidden: true,
      },
    ],
  },

  {
    title: 'Trait',
    icon: 'ActivityIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Traits',
        route: 'manage-traits',
      },
      {
        title: 'Trait Frequency',
        route: 'manage-trait-frequency',
      },
    ],
  },

  {
    title: 'Trait Logs',
    icon: 'ActivityIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Service Logs',
        route: 'manage-trait-logs',
      },
    ],
  },

  /* Others */
  {
    header: 'Others',
  },
  {
    title: 'Enter Update Log',
    route: 'manage-data-update',
    icon: 'RotateCwIcon',
  },
  {
    title: 'Version Control',
    route: 'manage-version-control',
    icon: 'RotateCwIcon',
  },
]
